import React from 'react';
import Layout from '@/components/_layout';
import Seo from '@/components/_layout/seo';
import { graphql, PageProps } from 'gatsby';
import ServiceBannerSection from '@/components/service-banner-section';
import BannerDescription from '@/components/banner-description';
import CaseStudiesSlider from '@/components/case-studies-slider';
import ExpertiseSection from '@/components/expertise-section';
import Blog from '@/components/blog';
import ClientsFeedback from '@/components/clients-feedback';
import Services from '@/components/services';
// import CreatingDemand from '@/components/creating-demand-section';
import { getTitle } from '@/utils/helper/getTitle';
import { hasInvalidBlogData, setBlogFeaturedData } from '@/utils/helper/blog';
import TechSeoSection from '@/components/tech-seo-section';
import BlockContent from '@/components/block-content';

const ServicesPage = ({ data, location, pageContext }: PageProps<any>): JSX.Element => {
  const {
    // @ts-ignore
    breadcrumb: { crumbs },
  } = pageContext;

  let blogList = data?.sanityServiceOpinions?.opinions;
  if (hasInvalidBlogData(blogList)) {
    blogList = setBlogFeaturedData(data?.allSanityBlog?.edges);
  }

  return (
    <Layout location={location} crumbs={crumbs}>
      <Seo
        title={data?.sanityServicePageMetadata?.service_page_metadata?.pageMetaName || 'Services'}
        description={data?.sanityServicePageMetadata?.service_page_metadata?.pageMetaDescription}
        pageMetadata={data?.sanityServicePageMetadata?.service_page_metadata?.metadata}
        canonical={data?.sanityServicePageMetadata?.service_page_metadata?.canonical}
      />
      <ServiceBannerSection
        backgroundImageUrl={data?.sanityServiceBanner?.background_image}
        title={getTitle(data?.sanityServiceBanner)}
        quoteText={data?.sanityServiceBanner?.sub_header}
        imageWidth="w-1/2"
      />
      <BannerDescription
        floating
        bannerPosition="right"
        // className="z-20"
        sectionTitle={data?.sanityServiceAbout?.title}
        body={<BlockContent blocks={data?.sanityServiceAbout?._rawDescription} />}
        bannerImageUrl={data?.sanityServiceAbout?.banner_image}
      />
      <Services.Category
        // title={data?.sanityServicesCategoryList?.title}
        title={getTitle(data?.sanityServicesCategoryList)}
        description={data?.sanityServicesCategoryList?.description}
        services_category={data?.sanityServicesCategoryList?.services_category}
      />
      <TechSeoSection
        sectionTitle={data?.sanityServiceTechSeo?.title}
        body={data?.sanityServiceTechSeo?._rawDescription}
        bannerImageUrl={data?.sanityServiceTechSeo?.banner_image}
        imageOrderFirst
      />
      {/* <CreatingDemand
        sectionTitle={data?.sanityServiceTechSeo?.title}
        body={data?.sanityServiceTechSeo?.description?._rawDescription}
        bannerImageUrl={data?.sanityServiceTechSeo?.banner_image}
      /> */}
      <CaseStudiesSlider
        isDark
        sectionTitle={{ solid: `Our Case Studies` }}
        caseStudies={data?.allSanityOurWorks?.edges}
        containerClasses={`pt-32 pb-32`}
      />
      <ExpertiseSection
        sectionTitle={data?.sanityServiceExpertise?.title}
        items={data?.sanityServiceExpertise?.expertises}
      />
      <Blog blogs={blogList} title={{ solid: data?.sanityServiceOpinions?.title }} />
      <ClientsFeedback
        title={{ solid: data?.sanityServiceClientsFeedback?.title }}
        feedbacks={data?.sanityServiceClientsFeedback?.service_clients_feedback}
        isGradient
      />
    </Layout>
  );
};

export default ServicesPage;

export const query = graphql`
  query servicePageQuery {
    sanityServicePageMetadata {
      service_page_metadata {
        metadata {
          metaContent
          metaName
        }
        canonical
        pageMetaDescription
        pageMetaName
      }
    }
    sanityServiceBanner {
      title {
        title_solid
        title_outlined
        is_outline_first
      }
      sub_header
      background_image {
        asset {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
    }
    sanityServiceAbout {
      title
      banner_image {
        asset {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
      _rawDescription
    }
    sanityServiceClientsFeedback {
      title
      service_clients_feedback {
        quote_primary
        quote_secondary
        client_name
      }
    }
    sanityServiceExpertise {
      title
      expertises {
        title
        description
      }
    }
    sanityServiceOpinions {
      title
      opinions {
        blog {
          id
          slug {
            current
          }
          title
          excerpt
          thumbnail {
            asset {
              gatsbyImageData(width: 500, placeholder: BLURRED)
            }
          }
        }
      }
    }
    sanityServiceTechSeo {
      id
      title
      _rawDescription
      banner_image {
        asset {
          gatsbyImageData(width: 500, placeholder: BLURRED)
        }
      }
    }
    # service_tech_seo {
    #   title
    #   banner_image {
    #     asset {
    #       gatsbyImageData(width: 500, placeholder: BLURRED)
    #     }
    #   }
    #   _rawDescription
    # }
    sanityServicesCategoryList {
      title {
        title_outlined
        title_solid
        is_outline_first
      }
      description
      services_category {
        title
        services {
          title
          description
          link
        }
      }
    }

    allSanityOurWorks {
      edges {
        node {
          id
          slug {
            current
          }
          title {
            title_solid
            title_outlined
            is_outline_first
          }
          excerpt
          case_study_title
          category {
            title
          }
          _rawBody
          banner {
            asset {
              gatsbyImageData(width: 500, placeholder: BLURRED)
            }
          }
          thumbnail {
            asset {
              gatsbyImageData(width: 500, placeholder: BLURRED)
            }
          }
        }
      }
    }
    allSanityBlog(limit: 3, sort: { fields: _createdAt, order: DESC }) {
      edges {
        node {
          id
          slug {
            current
          }
          title
          excerpt
          thumbnail {
            asset {
              gatsbyImageData(width: 500, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`;
